@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  background-color: #112d4e;
  ::-webkit-scrollbar {
    width: 3.5px;
  }
  
  ::-webkit-scrollbar-track {
    @apply bg-main
  }
  
  ::-webkit-scrollbar-thumb {
    @apply bg-sub-b
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background:#07d32c;
  }
}





body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.display-linebreak {
    white-space: pre-line;
}


:root {
  --swiper-theme-color: #ffd66d !important;
}

.homerest{
  background: rgb(11,0,20);
  min-height: 100vh;
  background-color: #FF3CAC;
  background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);  
  background-attachment: fixed;
  background-size: cover;
  @apply flex flex-wrap overflow-hidden z-10 
}

.cb-navbar{
  background-color: #FF3CAC;
  background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);  
  background-attachment: fixed;
  background-size: cover;
}
.cb-footer{
  background-color: #FF3CAC;
  background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);  
  background-attachment: fixed;
  background-size: cover;
}
.clip-pp{
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.rotating{
  animation: rotate 2.5s infinite linear;
  
  }
.rotating2{
  animation: rotate2 2.5s infinite linear;
}


@keyframes rotate {
  0%{
    transform: rotateY(0deg);  
  }
  25%{
    transform: rotateY(90deg);
  }  
  50%{
    transform: rotateY(180deg);
  }
  75%{
    transform: rotateY(270deg);
  }  
  100%{
    transform: rotateY(360deg);
  }
}
@keyframes rotate2 {
  0%{
    transform: rotateY(-180deg);  
    z-index: 0;  
  }
  24%{
    transform: rotateY(-90deg);
    z-index: 10;  
  }  
  50%{
    transform: rotateY(0deg);
    z-index: 20;  
  }
  74%{
    transform: rotateY(90deg);
    z-index: 10;  

  }  
  100%{
    transform: rotateY(180deg);
    z-index: 0;  
  }  
}


.loading-fill{
  animation: filling 3s;
}

@keyframes filling {
  0%{
    width: 0%; 
  }
  100%{
    width: 105%;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.wave{
  -webkit-clip-path: polygon(100% 0%, 0% 0% , 0% 65%, 1% 64.95%, 2% 64.8%, 3% 64.6%, 4% 64.3%, 5% 63.9%, 6% 63.45%, 7% 62.9%, 8% 62.25%, 9% 61.55%, 10% 60.8%, 11% 59.95%, 12% 59.05%, 13% 58.1%, 14% 57.1%, 15% 56.05%, 16% 55%, 17% 53.9%, 18% 52.8%, 19% 51.65%, 20% 50.5%, 21% 49.35%, 22% 48.2%, 23% 47.05%, 24% 45.9%, 25% 44.8%, 26% 43.75%, 27% 42.75%, 28% 41.75%, 29% 40.8%, 30% 39.9%, 31% 39.1%, 32% 38.35%, 33% 37.65%, 34% 37.05%, 35% 36.5%, 36% 36.05%, 37% 35.65%, 38% 35.35%, 39% 35.15%, 40% 35.05%, 41% 35%, 42% 35.05%, 43% 35.2%, 44% 35.45%, 45% 35.75%, 46% 36.15%, 47% 36.65%, 48% 37.2%, 49% 37.85%, 50% 38.55%, 51% 39.35%, 52% 40.2%, 53% 41.1%, 54% 42.05%, 55% 43.05%, 56% 44.1%, 57% 45.15%, 58% 46.3%, 59% 47.4%, 60% 48.55%, 61% 49.7%, 62% 50.85%, 63% 52%, 64% 53.15%, 65% 54.25%, 66% 55.35%, 67% 56.4%, 68% 57.45%, 69% 58.4%, 70% 59.35%, 71% 60.2%, 72% 61.05%, 73% 61.8%, 74% 62.45%, 75% 63.05%, 76% 63.6%, 77% 64.05%, 78% 64.4%, 79% 64.7%, 80% 64.85%, 81% 65%, 82% 65%, 83% 64.9%, 84% 64.75%, 85% 64.5%, 86% 64.2%, 87% 63.75%, 88% 63.25%, 89% 62.7%, 90% 62.05%, 91% 61.3%, 92% 60.5%, 93% 59.65%, 94% 58.75%, 95% 57.8%, 96% 56.8%, 97% 55.75%, 98% 54.65%, 99% 53.55%, 100% 52.4%);
  clip-path: polygon(100% 0%, 0% 0% , 0% 65%, 1% 64.95%, 2% 64.8%, 3% 64.6%, 4% 64.3%, 5% 63.9%, 6% 63.45%, 7% 62.9%, 8% 62.25%, 9% 61.55%, 10% 60.8%, 11% 59.95%, 12% 59.05%, 13% 58.1%, 14% 57.1%, 15% 56.05%, 16% 55%, 17% 53.9%, 18% 52.8%, 19% 51.65%, 20% 50.5%, 21% 49.35%, 22% 48.2%, 23% 47.05%, 24% 45.9%, 25% 44.8%, 26% 43.75%, 27% 42.75%, 28% 41.75%, 29% 40.8%, 30% 39.9%, 31% 39.1%, 32% 38.35%, 33% 37.65%, 34% 37.05%, 35% 36.5%, 36% 36.05%, 37% 35.65%, 38% 35.35%, 39% 35.15%, 40% 35.05%, 41% 35%, 42% 35.05%, 43% 35.2%, 44% 35.45%, 45% 35.75%, 46% 36.15%, 47% 36.65%, 48% 37.2%, 49% 37.85%, 50% 38.55%, 51% 39.35%, 52% 40.2%, 53% 41.1%, 54% 42.05%, 55% 43.05%, 56% 44.1%, 57% 45.15%, 58% 46.3%, 59% 47.4%, 60% 48.55%, 61% 49.7%, 62% 50.85%, 63% 52%, 64% 53.15%, 65% 54.25%, 66% 55.35%, 67% 56.4%, 68% 57.45%, 69% 58.4%, 70% 59.35%, 71% 60.2%, 72% 61.05%, 73% 61.8%, 74% 62.45%, 75% 63.05%, 76% 63.6%, 77% 64.05%, 78% 64.4%, 79% 64.7%, 80% 64.85%, 81% 65%, 82% 65%, 83% 64.9%, 84% 64.75%, 85% 64.5%, 86% 64.2%, 87% 63.75%, 88% 63.25%, 89% 62.7%, 90% 62.05%, 91% 61.3%, 92% 60.5%, 93% 59.65%, 94% 58.75%, 95% 57.8%, 96% 56.8%, 97% 55.75%, 98% 54.65%, 99% 53.55%, 100% 52.4%);
}


.red{
  clip-path: polygon(100% 0%, 0% 100%, 0 0);
  @apply bg-main h-full w-full absolute -z-50
}
.blue{
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  @apply bg-main h-full w-full absolute -z-50

}


.movingbar{
  animation: moving 4s infinite linear ;
}

@keyframes moving {
  0%{
    right: -100%;    
  }
  100%{
    right:100%
  }

}


.logolog{
  filter: invert(100%) sepia(1000%) saturate(0%) hue-rotate(101deg) brightness(1070%) contrast(105%);
}



.card:hover.image-full:before {
    transition: opacity 1s;
    opacity: 0.3;

  }


  .card.image-full:before {
    transition: opacity 1s;

  }

